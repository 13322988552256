class ActivityReport {
  constructor($compile, $http, $location, $routeParams, $scope, $rootScope, $timeout, $filter, $translate, ActivityReportFactory, TimeTrackingFactory, ErrFactory, TimeTrackingValidationService, Notification, LoaderFactory, AuthService, AppFactory, HolidaysService, ValidationErrorFactory) {
    Object.defineProperty(this, '$compile',                      { value: $compile                      });
    Object.defineProperty(this, '$http',                         { value: $http                         });
    Object.defineProperty(this, '$location',                     { value: $location                     });
    Object.defineProperty(this, '$routeParams',                  { value: $routeParams                  });
    Object.defineProperty(this, '$scope',                        { value: $scope                        });
    Object.defineProperty(this, '$rootScope',                    { value: $rootScope                    });
    Object.defineProperty(this, '$timeout',                      { value: $timeout                      });
    Object.defineProperty(this, '$filter',                       { value: $filter                       });
    Object.defineProperty(this, '$translate',                    { value: $translate                    });
    Object.defineProperty(this, 'activityReportFactory',         { value: ActivityReportFactory         });
    Object.defineProperty(this, 'authService',                   { value: AuthService                   });
    Object.defineProperty(this, 'appFactory',                    { value: AppFactory                    });
    Object.defineProperty(this, 'errFactory',                    { value: ErrFactory                    });
    Object.defineProperty(this, 'timeTrackingFactory',           { value: TimeTrackingFactory           });
    Object.defineProperty(this, 'timeTrackingValidationService', { value: TimeTrackingValidationService });
    Object.defineProperty(this, 'holidaysService',               { value: HolidaysService               });
    Object.defineProperty(this, 'loaderFactory',                 { value: LoaderFactory                 });
    Object.defineProperty(this, 'validationErrorFactory',        { value: ValidationErrorFactory        });
    Object.defineProperty(this, 'notification',                  { value: Notification                  });

    this.activityReport = null;
    this.dateFormat     = localStorage.getItem('date_format') || 'dd.MM.yyyy';
    this.today          = $filter('date')(new Date(), 'yyyy-MM-dd');
    this.deviceIsIOS    = deviceIsIOS;
    this.loading        = false;

    this.loaderFactory.show();
    Promise.resolve()
      .then(() => {
        let id = parseInt($routeParams.id);
        let path = $location.path();
        if (!Number.isNaN(id)) {
          if (path.includes('/activity-reports/submited/')) {
            let ar = this.$rootScope.submitedARs.find((item) => item.id === id)
            if (ar) return ar;
            else return Promise.reject();
          }
          else return ActivityReportFactory.getById(id)
            .then((ar) => {
              if (ar) return ar;
              else return Promise.reject();
            })
        } else return Promise.reject();
      })
      .then((ar) => {
        if (!ar.dailyReportList.length && !ar.readyToSubmit) {
          Promise.resolve()
          .then(() => new TimeTrackingFactory )
          .then((tt) => {
            tt.assignment = ar.assignment;
            return ar.dailyReportList.push(tt)})
        }
        this.activityReport = ar;
        this.validate();
        this.statusList = this.activityReport.assignment.can_release_later ?
          ActivityReportFactory.statusList :
          ActivityReportFactory.statusList.filter((s, index) => index !== 1);
        this.activityReport.reportStatus = this.activityReport.reportStatus || null;
        if (this.activityReport.attachment && this.checkIfLoaded()) return this.loadImage();
      })
      .then(() => this.loadHolidays(this.activityReport.assignment.zip_code, this.activityReport.startDate, this.activityReport.endDate))
      .then(() => {
        $timeout(function() {
          $scope.$apply();
        });
      })
      .catch((err) => {
        console.error(err);
        $location.path('/activity-report/preselected');
        $scope.$apply();
      })
      .then(() => this.loaderFactory.hide());

    $timeout(function() {
      let el = document.querySelectorAll('input[maxlength],textarea[maxlength]');
      el.forEach(e => {
        e.addEventListener('keyup', function(event) {
          this.value = this.value.substr(0, this.getAttribute("maxlength"));
          this.onkeyup && this.onkeyup();
        });
      });
    }, 100);
  }

  addDefaultDaily() {
    let startDate = new Date(this.activityReport.startDate);

    let defaultDaily = {
      id: 1,
      assignment: this.activityReport.assignment,
      project:    this.activityReport.assignment.prefillProject && this.activityReport.assignment.project,
      start_date: new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 8, 0, 0),
      end_date:   new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 17, 0, 0),
      pauses: [{
        start: new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 12, 0, 0),
        end:   new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 12, 30, 0),
      }]
    };
    this.activityReport.dailyReportList = [new this.timeTrackingFactory(defaultDaily)];
  }

  loadImage() {
    return Promise.resolve()
    .then(() => this.$http({
      url: this.activityReport.attachment,
      method: 'GET',
      responseType: 'blob'
    }))
    .then(response => {
      let blob = response.data;
      return URL.createObjectURL(blob);
    })
    .then(res => this.activityReport.attachment = res)
    .catch((err) => {
      if (err instanceof this.errFactory) err.notify();
      else console.error(err);
    });
  }

  validateDayInput() {
    let input = this.activityReport && this.activityReport.dailyReportList && this.activityReport.dailyReportList.length && this.activityReport.dailyReportList[0];
    if (input && !input.start_date) {
      let el = document.getElementsByClassName('input-date')[0];
      if (el && el.value && new Date(el.value).getTime() < new Date().getTime()) input.start_date = new Date(el.value);
    }
  }

  checkIfLoaded() {
    return typeof this.activityReport.attachment === 'string' && this.activityReport.attachment.includes('/time_tracking/api/');
  }

  mileageAvailable(tf) {
    let mm = this.activityReport.mileage_money_report;
    let mmReport = mm && mm.report_items;
    return mmReport && mmReport.length && mmReport.find(mmr => mmr.time_frame_id === tf.id);
  }

  async loadHolidays(state, start, end) {
    this.holidays = await this.holidaysService.loadHolidays(state, start, end);
  }

  isHoliday(day) {
    return day && this.holidays && this.holidays.find(h => h.date.getDate() === day.getDate());
  }

  openExistingHoliday(tf) {
    if (tf.holidays && tf.holidays.length) return this.notification.message(tf.holidays.map(h => h.name).join('\n'), 'short', 'top');
  }

  openHoliday(day) {
    if (this.isHoliday(day)) return this.notification.message(this.isHoliday(day).name, 'short', 'top');
  }

  isHolidayTracked() {
    return this.activityReport && this.activityReport.dailyReportList && this.activityReport.dailyReportList.find(tf => this.isHoliday(tf.startDate));
  }

  copy(dr) {
    if (dr instanceof this.timeTrackingFactory) {
      let id = Math.max(...this.activityReport.dailyReportList.map(d => d.id)) + 1;
      let newDR = new this.timeTrackingFactory(Object.assign(dr.toJSON(), {assignment: this.activityReport.assignment, id }));
      let startDate = new Date(newDR.start_date);
      startDate.setDate(startDate.getDate() + 1);

      while (
        this.activityReport.dailyReportList.some((dr) => {
          let sDR = (new Date(dr.start_date)).setHours(0,0,0,0);
          let sNDR = new Date(startDate).setHours(0,0,0,0);
          return sDR === sNDR
        })
      ) {
        startDate.setDate(startDate.getDate() + 1);
      }
      if (startDate > this.activityReport.endDate) return new this.errFactory('activityReport.reports_within_a_week_error').notify();
      if (startDate > new Date()) return new this.errFactory.FutureAR().notify();
      newDR.updateDate(startDate);
      this.activityReport.dailyReportList.push(newDR);
      this.validate();
    }
  }

  delete(dr) {
    return this.notification.confirm_p({
      title: 'confirm',
      desc: 'questions.deleteDaily',
      cancelButton: 'no',
      button: 'yes'
    })
    .then((res) => {
      if (res !== 2) return Promise.reject(new this.errFactory.QuietError);
    })
    .then(() => {
      this.$scope.$apply(() => {
        this.activityReport.dailyReportList.splice(this.activityReport.dailyReportList.indexOf(dr), 1);
        this.validate();
      });
    });
  }

  confirm() {
    Promise.resolve()
    .then(() => this.authService.loadUser())
    .then(user => this.checkMileageMoney(user))
    .then(() => this.activityReport.save())
    .then(() => {
      this.$location.path(`/activity-report/${this.activityReport.id}/confirm`);
      this.$scope.$apply();
    })
    .catch((err) => {
      if (err instanceof this.errFactory) return err.notify();
      else {
        this.dayError = err.errors && err.errors.some(item => item instanceof this.timeTrackingValidationService.ReportsWithinAWeekError ||
                                                              item instanceof this.timeTrackingValidationService.FutureReportError);
        console.error(err);
      }
    })
  }

    async checkMileageMoney(user) {
    if (user && user.address && this.activityReport.assignment.mileage_money_time_frames && this.activityReport.assignment.mileage_money_time_frames.length) {
      let valid = 0;
      for (let i=0; i<this.activityReport.dailyReportList.length; i++) {
        this.activityReport.dailyReportList[i].valid_mileage_money = !!this.activityReport.assignment.mileage_money_time_frames.find((mtf => 
          this.activityReport.dailyReportList[i].start_date >= new Date(mtf.starts_at) &&
          this.activityReport.dailyReportList[i].start_date <= new Date(mtf.ends_at)
        ));
        valid = +valid + +this.activityReport.dailyReportList[i].valid_mileage_money;
      }
      if (valid) return await new Promise((res, rej) => {
        let angElem = angular.element('<mileage-money-popup valid="valid" valid-count="validCount" license-plate="licensePlate" license-plate-country="licensePlateCountry" on-hide="onHide(ind)"></mileage-money-popup>');
        let newScope = this.$scope.$new(true);
        newScope.valid = valid;
        newScope.validCount = this.activityReport.dailyReportList.length;
        newScope.licensePlate = user.license_plate;
        newScope.licensePlateCountry = user.license_plate_country || 'DE';
        newScope.onHide = (ind) => {
          if (ind) {
            this.activityReport.mileage_money = true;
            res(ind);
          }
          else throw new this.errFactory.QuietError;
        };
        angular.element(document).find('ng-view').append(this.$compile(angElem)(newScope));
      })
    }
  }

  addPause (dr) {
    let dailyStart = dr.pauses.length ?
                     dr.pauses[dr.pauses.length - 1].end :
                     new Date(dr.start_date.getTime() + (dr.end_date - dr.start_date) / 2);
    dailyStart.setSeconds(0);
    dr.pauses.push(new this.timeTrackingFactory.Pause({
      start: new Date(+dailyStart),
      end:   new Date(+dailyStart + 30*60*1000),
    }, dr));
    this.validate();
  }

  back() {
    this.$location.path(`/activity-report/preselected`);
  }

  cancelConfirm() {
    if (this.activityReport.readyToSubmit) this.$location.path('/daily-report-list');
    else this.$location.path(`/activity-report/${this.activityReport.id}`);
  }

  deleteConfirm() {
    return this.notification.confirm_p({
        title: 'confirm',
        desc: 'questions.deleteActivity',
        cancelButton: 'no',
        button: 'yes'
    })
    .then((res) => {
      if (res !== 2) return Promise.reject(new this.errFactory.QuietError);
    })
    .then(() => this.activityReport.remove())
    .then(() => {
      this.$location.path('/daily-report-list');
      this.$scope.$apply();
    })
    .catch((err) => {
      if (err instanceof this.errFactory) err.notify();
      else console.error(err);
    })
  };

  backToSubmited() {
    this.$location.path(`/activity-reports/submited`);
  }

  openSplitReport(id) {
    this.loaderFactory.show();
    if (this.$rootScope.submitedARs.find((item) => item.id === id)) this.$location.path(`/activity-reports/submited/${id}`);
    else this.activityReportFactory.SubmittedActivityReport.loadWorkingPeriodById(id)
    .then(wp => wp.save())
    .then(wp => this.$rootScope.submitedARs.push(wp))
    .then(() => {
      this.$location.path(`/activity-reports/submited/${id}`);
      this.$scope.$apply();
    })
    .catch((err) => {
      if (err instanceof this.errFactory) err.notify();
      else console.error(err);
      this.loaderFactory.hide();
    });
  }

  submitReport() {
    let self = this;
    this.loading = true;
    this.loaderFactory.show();
    Promise.resolve()
    .then(() => this.activityReport.save())
    .then(() => this.appFactory.checkNewVersion())
    .then(() => this.activityReport.submit())
    .then(res => {
      let calls = [];
      if (!res.length) calls.push(res);
      else calls = [...res];
      return Promise.all(calls.map(a => 
        this.activityReportFactory.SubmittedActivityReport.fromAPIData(a)
        .then(ar => ar.save('working_periods'))
      ))
      .then(() => this.activityReport.remove())
      .then(() => this.timeTrackingFactory.getOwn())
      .then((list) => {
        this.loaderFactory.hide();
        return this.notification.alert({
          title: 'activityReport.successfulTitle',
          desc: calls.length > 1 ? 'activityReport.successfulMessageSplitReports' : 'activityReport.successfulMessage'
        }, function() {
          return self.$scope.$apply(function() {
            if (list.length) {
              self.$location.path('/activity-report/preselected');
            } else {
              self.$location.path('/');
            }
          });
        });
      })
    })
    .catch((err) => {
      this.loaderFactory.hide();
      if (err && err.store_url) {
        this.$location.path('/blocker');
        this.$scope.$apply();
      }
      if (err instanceof this.errFactory) {
        err.notify(() => {
          this.activityReport.readyToSubmit = true;
          this.$scope.$apply();
          this.activityReport.save();
          this.loading = false;
        });
      } else {
        console.error(err);
        this.loading = false;
      }
    });
  }

  resubmitPhotoTn() {
    this.loading = true;
    this.loaderFactory.show();

    this.activityReport.submit()
    .then(() => this.activityReport.remove())
    .then(() => {
      this.loaderFactory.hide();
      return this.notification.alert({
        title: 'activityReport.successfulTitle',
        desc: 'activityReport.successfulMessage'
      }, this.appFactory.goBack());
    })
    .catch((err) => {
      this.loaderFactory.hide();
      if (err && err.store_url) {
        this.$location.path('/blocker');
        this.$scope.$apply();
      }
      if (err instanceof this.errFactory) err.notify(() => this.loading = false);
      else {
        console.error(err);
        this.loading = false;
      }
    });
  }

  emailCustomersDetails() {
    this.notification.message(
      `${this.activityReport.signature_confirmation.customers_emails.join(',\n')}
       ${this.$filter('date')(this.activityReport.signature_confirmation.created_at, 'HH:mm dd.MM.yyyy')}`
    );
  }

  validate(confrim) {
    this.errorList             = [];
    this.activityReport.errors = [];
    this.activityReport.dailyReportList.forEach(d => {
      d.errorList = [];
      d.errors    = [];
      if (d.pauses && d.pauses.length) d.pauses.forEach(p => { p.errors = []; });

      d.end_date.setDate(    d.start_date.getDate());
      d.end_date.setMonth(   d.start_date.getMonth());
      d.end_date.setFullYear(d.start_date.getFullYear());
      if (d.end_date < d.start_date) d.end_date.setDate(d.start_date.getDate() + 1);
    });
    this.reloadTrigger = new Date();

    this.$timeout(() => {
      this.timeTrackingValidationService.validateAR(this.activityReport, confrim);

      this.errorList = [...this.activityReport.errors];
      this.activityReport.dailyReportList.forEach(d => {
        d.errorList = [...d.errors];
        if (d.pauses && d.pauses.length) d.pauses.forEach(p => { d.errorList = [...d.errorList, ...p.errors]; });
        this.errorList = [...this.errorList, ...d.errorList];

        this.getErrorClass(d);
        d.displayErrorsTech  = [...new Set(d.errorList.filter(err => err instanceof this.validationErrorFactory.TechnicalError).map(err => err.messageOptions ? [err.message, err.messageOptions] : err.message))];
        d.displayErrorsLegal = [...new Set(d.errorList.filter(err => err instanceof this.validationErrorFactory.LegalError).map(err => err.message))];

        this.statesTrigger = new Date();
      });
    });
  }

  getErrorClass(daily) {
    daily.errorsClasses = {};
    if (daily.errors.length) {
      daily.errorsClasses.dateError = daily.errors.find(err => err instanceof this.validationErrorFactory.DailyError);

      let fullDailyErrors = daily.errors.filter(e =>  !e.dailyStart && !e.dailyEnd);
      if (fullDailyErrors.length) {
        daily.errorsClasses.techErr  = fullDailyErrors.find(err => err instanceof this.validationErrorFactory.TechnicalError);
        daily.errorsClasses.legalErr = fullDailyErrors.find(err => err instanceof this.validationErrorFactory.LegalError);
      }

      let startDailyError = daily.errors.filter(e => e.dailyStart);
      if (startDailyError.length) {
        daily.errorsClasses.techErrStart  = startDailyError.find(err => err instanceof this.validationErrorFactory.TechnicalError);
        daily.errorsClasses.legalErrStart = startDailyError.find(err => err instanceof this.validationErrorFactory.LegalError);
      }

      let endDailyError = daily.errors.filter(e => e.dailyEnd);
      if (endDailyError.length) {
        daily.errorsClasses.techErrEnd  = endDailyError.find(err => err instanceof this.validationErrorFactory.TechnicalError);
        daily.errorsClasses.legalErrEnd = endDailyError.find(err => err instanceof this.validationErrorFactory.LegalError);
      }
    } else {
      daily.errorsClasses.techErr  = null;
      daily.errorsClasses.legalErr = null;
    }
  }

  passValidation(callback, confrim = null) {
    if (confrim) this.validate(confrim);
    this.$timeout(() => {
      if (this.errorList && this.errorList.length) {
        let techErrors      = this.errorList.filter(err => err instanceof this.validationErrorFactory.TechnicalError);
        let legalErrors     = this.errorList.filter(err => err instanceof this.validationErrorFactory.LegalError);

        if (confrim && this.statusError || this.signatureError || techErrors.length) return Promise.resolve()
        .then(() => {
          if      (this.statusError    && this.statusError.message)    return this.statusError.message;
          else if (this.signatureError && this.signatureError.message) return this.signatureError.message;
          else if (techErrors.length) return Promise.all(techErrors.map(err => err.messageOptions ? [err.message, err.messageOptions] : err.message)
                                                                  .map(e => Array.isArray(e) ? this.$translate(...e) : this.$translate(e)));
        })
        .then(t => this.notification.alert({
          title: 'errors.checkHighlightedFields',
          desc: Array.isArray(t) ? [...new Set(Object.values(t))].map(e => `- ${e};`).join('\n') : t
        }));

        if (!confrim && legalErrors.length) return this.$translate([...new Set(legalErrors.map(e => e.message)), 'timeTrackings.saveAnyway'])
        .then(t => this.notification.confirm_p({
          title: 'timeTrackings.workingHoursAct',
          desc: Object.values(t).splice(-Object.values(t).length, Object.values(t).length - 1).map(e => `- ${e};`).join('\n') + `\n\n${t['timeTrackings.saveAnyway']}`,
          cancelButton: 'no',
          button: 'yes'
        }).then((res) => {
          if (res === 2) callback.apply(this);
        }));
        else callback.apply(this);
      } else callback.apply(this);
    });
  }

  get statusError() {
    return this.errorList && this.errorList.find(err => err instanceof this.validationErrorFactory.StatusError);
  }

  get signatureError() {
    return this.errorList && this.errorList.find(err => err instanceof this.validationErrorFactory.SignatureError);
  }

  isArray(val) {
    return Array.isArray(val);
  }

}

app.component('activityReport', {
  template: require('scripts/components/time-trackings/activity-report/activity-report.html'),
  controller: ActivityReport
});

app.component('confirmActivityReport', {
  template: require('scripts/components/time-trackings/activity-report/confirm-activity-report.html'),
  controller: ActivityReport,
  bindings: {
    onDelete: '&'
  }
});

app.component('submitedActivityReport', {
  template: require('scripts/components/time-trackings/activity-report/submited-activity-report.html'),
  controller: ActivityReport
});
